import React from "react";

class PageSubHeader extends React.Component {

    render() {
        return(
            <div id={this.props.id} className="text-center lg:text-left pb-2 lg:pb-4">
                <h1 className="font-yu-mincho text-lg lg:text-2xl font-black inline-block px-12 lg:px-0 border-solid lg:border-none border-b border-b-black">
                    {this.props.text}</h1>
            </div>
        )
    }

}

export default PageSubHeader;