import React from "react";

import lineImg from '../../images/menu-line.svg';

class PageHader extends React.Component {

    render( ) {
        return(
        <div className='mb-7 mt-4 text-center lg:text-left'>
            <h1 className='text-base lg:text-4xl'>{this.props.text}  <img src={lineImg} className="w-5 inline-block mx-4" /><span className='text-sm lg:text-2xl font-yu-mincho'>{this.props.subText}</span></h1>
        </div>
        )
    }

}

export default PageHader;