// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/other-class-bg.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/other-class-bg.avif", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../images/other-class-bg.webp", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../images/other-class-bg_2x.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../images/other-class-bg_2x.avif", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../../images/other-class-bg_2x.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#other-class-head{background-size:100%;background-repeat:no-repeat;background-position:center}html.fallback #other-class-head{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}html.avif #other-class-head{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}html.webp #other-class-head{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@media screen and (-webkit-min-device-pixel-ratio: 2),screen and (min-resolution: 2dppx),screen and (min-resolution: 192dpi){html.fallback #other-class-head{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}html.avif #other-class-head{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}html.webp #other-class-head{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
