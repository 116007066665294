import React from "react";

class ClassNotification extends React.Component {

    render() {
        return(
            <div className="lg:flex lg:pl-2">
                <div className="text-center mb-2"><h4 className="font-yu-mincho text-base lg:vertical-text">注意事項</h4></div>
                <div className="flex justify-center lg:justify-start lg:ml-4">
                    <ul className="font-yu-mincho text-sm">
                        <li>・コロナウイルス問題等の諸事情により、変更の可能性がありますことをご理解ください。</li>
                        <li>・各教室にお出かけの際は、事前にご自分の体調のチェック、発熱等の確認をお願いいたします。</li>
                        <li>・各教室の換気には十分注意して、マスクの着用や手の消毒にご協力をお願いいたします。</li>
                    </ul>
                </div>
            </div>
        )
    }

}

export default ClassNotification;