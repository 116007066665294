
import React from "react";

import mainLogo from '../../images/main-logo.png';
import mainLogo2x from '../../images/main-logo_2x.png';
import mainLogo3x from '../../images/main-logo_3x.png';
import mainLogoAvif from '../../images/main-logo.avif';
import mainLogo2xAvif from '../../images/main-logo_2x.avif';
import mainLogo3xAvif from '../../images/main-logo_3x.avif';
import mainLogoWebp from '../../images/main-logo.webp';
import mainLogo2xWebp from '../../images/main-logo_2x.webp';
import mainLogo3xWebp from '../../images/main-logo_3x.webp';
import spMainLogo from '../../images/logo1.png';
import spMainLogo2x from '../../images/logo1_2x.png';
import spMainLogo3x from '../../images/logo1_3x.png';
import spMainLogoAvif from '../../images/logo1.avif';
import spMainLogo2xAvif from '../../images/logo1_2x.avif';
import spMainLogo3xAvif from '../../images/logo1_3x.avif';
import spMainLogoWebp from '../../images/logo1.webp';
import spMainLogo2xWebp from '../../images/logo1_2x.webp';
import spMainLogo3xWebp from '../../images/logo1_3x.webp';
import spMenuBtn from '../../images/sp-menu-btn.png';
import spMenuBtn2x from '../../images/sp-menu-btn_2x.png';
import spMenuBtn3x from '../../images/sp-menu-btn_3x.png';
import spMenuBtnAvif from '../../images/sp-menu-btn.avif';
import spMenuBtn2xAvif from '../../images/sp-menu-btn_2x.avif';
import spMenuBtn3xAvif from '../../images/sp-menu-btn_3x.avif';
import spMenuBtnWebp from '../../images/sp-menu-btn.webp';
import spMenuBtn2xWebp from '../../images/sp-menu-btn_2x.webp';
import spMenuBtn3xWebp from '../../images/sp-menu-btn_3x.webp';

import '../../styles/components/header.scss';

class TaichiHeader extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
        <header className="bg-white w-full fixed lg:relative  top-0 z-40">
            <div id="pc-nav" className="hidden lg:flex z-40 justify-center py-9">
            <nav className="flex items-end align-middle pb-7">
                <ul className="grid grid-cols-2">
                    <li className={(this.props.active == "news" ? 'active' : "")}><a className="inline-block lg:w-32 xl:w-36 lg:text-2xl xl:text-3xl text-center lg:mr-2.5 xl:mr-3.5" href="/news">news</a></li>
                    <li className={(this.props.active == "class" ? 'active' : "")}><a className="inline-block lg:w-32 xl:w-36 lg:text-2xl xl:text-3xl text-center lg:mr-5 xl:mr-7" href="/class">class</a></li>
                </ul>
            </nav>
            <div id="headerLogo"  className="lg:w-32 xl:w-40">
                <a href="/">
                    <picture>
                        <source srcSet={`${mainLogoAvif}, ${mainLogo2xAvif} 2x, ${mainLogo3xAvif} 3x`} type="image/avif" />
                        <source srcSet={`${mainLogoWebp}, ${mainLogo2xWebp} 2x, ${mainLogo3xWebp} 3x`} type="image/webp" />
                        <source srcSet={`${mainLogo},${mainLogo2x} 2x, ${mainLogo3x} 3x`} type="image/png" />
                        <img loading="lazy" src={ mainLogo } alt="太極-悠悠 World Chenpanling Family"  />
                    </picture>
                </a>
            </div>
            <nav className="flex items-end align-middle pb-7">
                <ul className="grid grid-cols-2">
                    <li className={(this.props.active == "blog" ? 'active' : "")}><a className="inline-block lg:w-32 xl:w-36 lg:text-2xl xl:text-3xl text-center  lg:mr-2.5 xl:mr-3.5 lg:ml-5 xl:ml-7" href="/blogs">blog</a></li>
                    <li className={(this.props.active == "contact" ? 'active' : "")}><a className="inline-block lg:w-32 xl:w-36 lg:text-2xl xl:text-3xl text-center " href="/contact">contact</a></li>
                </ul>
            </nav>
            </div>
            <div id="mobile-nav" className="bg-white flex lg:hidden py-5 px-3.5 w-full">
                <div className="mr-auto">
                    <a href="/">
                        <picture>
                            <source srcSet={`${spMainLogoAvif}, ${spMainLogo2xAvif} 2x, ${spMainLogo3xAvif} 3x`} type="image/avif" />
                            <source srcSet={`${spMainLogoWebp}, ${spMainLogo2xWebp} 2x, ${spMainLogo3xWebp} 3x`} type="image/webp" />
                            <source srcSet={`${spMainLogo},${spMainLogo2x} 2x, ${spMainLogo3x} 3x`} type="image/png" />
                            <img src={ spMainLogo } alt="太極-悠悠 World Chenpanling Family" />
                        </picture>
                    </a>
                </div>
                <div className="flex justify-center ml-auto items-center align-middle">
                    <a href="" onClick={this.props.openNav}>
                    <picture>
                        <source srcSet={`${spMenuBtnAvif}, ${spMenuBtn2xAvif} 2x, ${spMenuBtn3xAvif} 3x`} type="image/avif" />
                        <source srcSet={`${spMenuBtnWebp}, ${spMenuBtn2xWebp} 2x, ${spMenuBtn3xWebp} 3x`} type="image/webp" />
                        <source srcSet={`${spMenuBtn},${spMenuBtn2x} 2x, ${spMenuBtn3x} 3x`} type="image/png" />
                        <img src={ spMenuBtn } alt="menu" />
                    </picture>                    
                    </a>
                </div>
            </div>
        </header>
        )
    }

}

export default TaichiHeader;