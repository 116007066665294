import React from "react";

class CommonApp extends React.Component {

    /**
     * 
     * @param {Event} e 
     */
     checkAvailableImageFormat(e)
     {
         var avif = new Image();
         avif.onload = function () {
             document.documentElement.classList.add('avif');
         }
 
         avif.onerror = function () {
             
             var webpImg = new Image();
             webpImg.onload = function (e) {
                 document.documentElement.classList.add('webp');
             }
             webpImg.onerror = function(e) {
                 document.documentElement.classList.add('fallback');
             }
             webpImg.src = 'data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==';
         };
 
         avif.src = "data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=";
 
     }
     
    openNav(e) {
        e.preventDefault();
        this.setState({
            openOverlay: true
        })
    }
    
    closeNav(e) {
        e.preventDefault();
        this.setState({
            openOverlay: false
        })
        
    }

}

export default CommonApp;