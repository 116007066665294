import React from "react";
import '../../styles/components/other-class-header.scss';

class OtherClassHeader extends React.Component {

    render() {
        return (
            <div className="guide-sub-header text-center lg:text-left mb-5 mt-8">
                <h2 id="other-class-head" className="w-[8rem] lg:w-40 text-center font-yu-mincho text-base lg:text-xl font-black inline-block">その他の教室</h2>
            </div>
        )
    }

}

export default OtherClassHeader;