import React from "react";

import mainFootLogo from '../../images/logo2.png';
import mainFootLogo2x from '../../images/logo2_2x.png';
import mainFootLogo3x from '../../images/logo2_3x.png';
import mainFootLogoAvif from '../../images/logo2.avif';
import mainFootLogo2xAvif from '../../images/logo2_2x.avif';
import mainFootLogo3xAvif from '../../images/logo2_3x.avif';
import mainFootLogoWebp from '../../images/logo2.webp';
import mainFootLogo2xWebp from '../../images/logo2_2x.webp';
import mainFootLogo3xWebp from '../../images/logo2_3x.webp';

import IconFb from '../../images/icon-fb.png';
import IconFb2x from '../../images/icon-fb_2x.png';
import IconFb3x from '../../images/icon-fb_3x.png';
import IconFbAvif from '../../images/icon-fb.avif';
import IconFb2xAvif from '../../images/icon-fb_2x.avif';
import IconFb3xAvif from '../../images/icon-fb_3x.avif';
import IconFbWebp from '../../images/icon-fb.webp';
import IconFb2xWebp from '../../images/icon-fb_2x.webp';
import IconFb3xWebp from '../../images/icon-fb_3x.webp';
import IconInst from '../../images/icon-inst.png';
import IconInst2x from '../../images/icon-inst_2x.png';
import IconInst3x from '../../images/icon-inst_3x.png';
import IconInstAvif from '../../images/icon-inst.avif';
import IconInst2xAvif from '../../images/icon-inst_2x.avif';
import IconInst3xAvif from '../../images/icon-inst_3x.avif';
import IconInstWebp from '../../images/icon-inst.webp';
import IconInst2xWebp from '../../images/icon-inst_2x.webp';
import IconInst3xWebp from '../../images/icon-inst_3x.webp';
import IconLine from '../../images/icon-line.png';
import IconLine2x from '../../images/icon-line_2x.png';
import IconLine3x from '../../images/icon-line_3x.png';
import IconLineAvif from '../../images/icon-line.avif';
import IconLine2xAvif from '../../images/icon-line_2x.avif';
import IconLine3xAvif from '../../images/icon-line_3x.avif';
import IconLineWebp from '../../images/icon-line.webp';
import IconLine2xWebp from '../../images/icon-line_2x.webp';
import IconLine3xWebp from '../../images/icon-line_3x.webp';
import IconYoutube from '../../images/icon-youtube.png';
import IconYoutube2x from '../../images/icon-youtube_2x.png';
import IconYoutube3x from '../../images/icon-youtube_3x.png';
import IconYoutubeAvif from '../../images/icon-youtube.avif';
import IconYoutube2xAvif from '../../images/icon-youtube_2x.avif';
import IconYoutube3xAvif from '../../images/icon-youtube_3x.avif';
import IconYoutubeWebp from '../../images/icon-youtube.webp';
import IconYoutube2xWebp from '../../images/icon-youtube_2x.webp';
import IconYoutube3xWebp from '../../images/icon-youtube_3x.webp';

class TaichiFooter extends React.Component {

    render() {
        return(
            <footer className={"bg-yuyu-sun p-3.5 lg:p-14 lg:pb-12 " + (this.props.fixed && 'fixed bottom-0 w-full')}>
                <div id="foot-logo" className="w-32 lg:w-[17rem] justify-center items-center flex mx-auto mb-3.5 lg:mb-12">
                    <picture>
                        <source srcSet={`${mainFootLogoAvif}, ${mainFootLogo2xAvif} 2x, ${mainFootLogo3xAvif} 3x`} type="image/avif" />
                        <source srcSet={`${mainFootLogoWebp}, ${mainFootLogo2xWebp} 2x, ${mainFootLogo3xWebp} 3x`} type="image/webp" />
                        <source srcSet={`${mainFootLogo},${mainFootLogo2x} 2x, ${mainFootLogo3x} 3x`} type="image/png" />
                        <img loading="lazy" src={ mainFootLogo } alt="太極-悠悠 World Chenpanling Family" />
                    </picture>   
                </div>
                <div id="foot-info" className="text-yuyu-black lg:flex lg:justify-center lg:items-center pb-1 lg:mb-5 text-base">
                    <div className="flex justify-center mb-2.5 lg:px-10 whitespace-nowrap lg:border-r border-solid border-yuyu-black">
                        <div className="mr-6">〒819-1331</div>
                        <div>糸島志摩久家468-1</div>
                    </div>
                    <div className="flex justify-center mb-2.5 lg:px-10 whitespace-nowrap lg:border-r border-solid border-yuyu-black">
                        <div className="mr-4">電話番号</div>
                        <div className="">080-3901-6726</div>
                    </div>
                    <div className="flex justify-center mb-2.5 lg:px-10 whitespace-nowrap">
                        <div>chenpanlingjapan2013@gmail.com</div>
                    </div>
                </div> 
                <div id="foot-social" className="flex justify-around mx-auto w-64 lg:w-[31rem]">
                    <div className="w-12">
                        <a href="https://www.instagram.com/xiaoyi1030/?utm_medium=copy_link" className="hover:opacity-70">
                            <picture>
                                <source srcSet={`${IconInstAvif}, ${IconInst2xAvif} 2x, ${IconInst3xAvif} 3x`} type="image/avif" />
                                <source srcSet={`${IconInstWebp}, ${IconInst2xWebp} 2x, ${IconInst3xWebp} 3x`} type="image/webp" />
                                <source srcSet={`${IconInst},${IconInst2x} 2x, ${IconInst3x} 3x`} type="image/png" />
                                <img loading="lazy" src={ IconInst } alt="太極-悠悠 World Chenpanling Family" />
                            </picture> 
                        </a> 
                    </div>
                    <div className="w-12">
                        <a href="https://www.youtube.com/channel/UCQQVaVsecuJryXDgNucKbZA" className="hover:opacity-70">
                            <picture>
                                <source srcSet={`${IconYoutubeAvif}, ${IconYoutube2xAvif} 2x, ${IconYoutube3xAvif} 3x`} type="image/avif" />
                                <source srcSet={`${IconYoutubeWebp}, ${IconYoutube2xWebp} 2x, ${IconYoutube3xWebp} 3x`} type="image/webp" />
                                <source srcSet={`${IconYoutube},${IconYoutube2x} 2x, ${IconYoutube3x} 3x`} type="image/png" />
                                <img loading="lazy" src={ IconYoutube } alt="太極-悠悠 World Chenpanling Family" />
                            </picture>
                        </a>
                    </div>
                    <div className="w-12">
                        <a href="https://m.facebook.com/xiaoyi.chen.376?fref=nf" className="hover:opacity-70">
                            <picture>
                                <source srcSet={`${IconFbAvif}, ${IconFb2xAvif} 2x, ${IconFb3xAvif} 3x`} type="image/avif" />
                                <source srcSet={`${IconFbWebp}, ${IconFb2xWebp} 2x, ${IconFb3xWebp} 3x`} type="image/webp" />
                                <source srcSet={`${IconFb},${IconFb2x} 2x, ${IconFb3x} 3x`} type="image/png" />
                                <img loading="lazy" src={ IconFb } alt="太極-悠悠 World Chenpanling Family" />
                            </picture>
                        </a> 
                    </div>
                    <div className="w-12">
                        <a href="https://lin.ee/nDlU3q1" className="hover:opacity-70">
                            <picture>
                                <source srcSet={`${IconLineAvif}, ${IconLine2xAvif} 2x, ${IconLine3xAvif} 3x`} type="image/avif" />
                                <source srcSet={`${IconLineWebp}, ${IconLine2xWebp} 2x, ${IconLine3xWebp} 3x`} type="image/webp" />
                                <source srcSet={`${IconLine},${IconLine2x} 2x, ${IconLine3x} 3x`} type="image/png" />
                                <img loading="lazy" src={ IconLine } alt="太極-悠悠 World Chenpanling Family" />
                            </picture>
                        </a>
                    </div>
                </div>
            </footer>
        )
    }

}

export default TaichiFooter;