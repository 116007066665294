import React from "react";
import '../../styles/components/normal-class-price-header.scss';

class NormalClassPriceHeader extends React.Component {

    render() {
        return (
            <div id="normal-price-header" className="guide-sub-header mt-8 text-center lg:text-left mb-3">
                <h2 className="w-[10rem] lg:w-40 inline-block text-center font-yu-mincho text-base lg:text-xl font-black">通常教室の料金</h2>
            </div>
        )
    }

}

export default NormalClassPriceHeader;