import React from "react";
import '../../styles/components/fade.scss';

class Fade extends React.Component {

    constructor(props)
    {
        super(props);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
    }

    render() {
        return (
            <div className={(this.props.openOverlay ? 'open' : 'close') + " fade " + (this.props.exClass ? this.props.exClass : '')} onTransitionEnd={this.onTransitionEnd}>
                {this.props.children} 
            </div>
        )
    }

    onTransitionEnd(e) {
        console.log('end amimation');
        if(!this.props.openOverlay) {
            e.target.className += ' left-full'
        }
    }

}

export default Fade;