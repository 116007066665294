import React from 'react';
import '../../styles/components/float-navigation.scss';
import spMainLogo from '../../images/logo1.png';
import spMainLogo2x from '../../images/logo1_2x.png';
import spMainLogo3x from '../../images/logo1_3x.png';
import spMainLogoAvif from '../../images/logo1.avif';
import spMainLogo2xAvif from '../../images/logo1_2x.avif';
import spMainLogo3xAvif from '../../images/logo1_3x.avif';
import spMainLogoWebp from '../../images/logo1.webp';
import spMainLogo2xWebp from '../../images/logo1_2x.webp';
import spMainLogo3xWebp from '../../images/logo1_3x.webp';


class FloatNavigation extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            scrollAmount: 0,
            headerHeight: 280
        }

        this.updateScroll = this.updateScroll.bind(this);

    }

    componentDidMount() {
        window.addEventListener('scroll', this.updateScroll);
    }

    updateScroll() {
        let header = document.getElementsByTagName('header').length && document.getElementsByTagName('header')[0];

        let headerHeight = 280;
        if (header) {
            headerHeight = header.offsetTop +  header.offsetHeight;
        }

        this.setState({
            scrollAmount: window.scrollY,
            headerHeight: headerHeight
        })
    }

    render(){
        return(
            <div id='float-navigation' className={"z-50 top-0 left-0 fixed w-full hidden lg:block " + (this.state.scrollAmount > (this.state.headerHeight + 100) ? "show": 'hide')}>
                <div className='shadow-md  w-[850px] h-[95px] bg-white mx-auto flex items-center justify-center'>
                    <ul className='flex justify-center items-center'>
                        <li className='text-3xl mr-16 hover:opacity-75'><a href='/news'>news</a></li>
                        <li className='text-3xl mr-16 hover:opacity-75'><a href='/class'>class</a></li>
                        <li className='text-3xl  hover:opacity-75'>
                            <a href="/">
                                <picture>
                                    <source srcSet={`${spMainLogoAvif}, ${spMainLogo2xAvif} 2x, ${spMainLogo3xAvif} 3x`} type="image/avif" />
                                    <source srcSet={`${spMainLogoWebp}, ${spMainLogo2xWebp} 2x, ${spMainLogo3xWebp} 3x`} type="image/webp" />
                                    <source srcSet={`${spMainLogo},${spMainLogo2x} 2x, ${spMainLogo3x} 3x`} type="image/png" />
                                    <img  className='w-36' src={ spMainLogo } alt="太極-悠悠 World Chenpanling Family" />
                                </picture>
                            </a>
                        </li>
                        <li className='text-3xl ml-16 hover:opacity-75'><a href='/blogs'>blog</a></li>
                        <li className='text-3xl ml-16 hover:opacity-75'><a href='/contact'>contact</a></li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default FloatNavigation;
