import React from "react";
import '../../styles/components/normal-class-header.scss';

class NormalClassHeader extends React.Component {

    render() {
        return (
            <div className="lg:flex lg:items-center mt-7">
                <div className="guide-sub-header text-center mb-3">
                    <h2 className="normal-class-head text-base font-yu-mincho font-bold inline-block w-24 text-center lg:text-xl">通常教室</h2>
                </div>
                <div className="lg:ml-6 lg:pt-2"><p className="font-yu-mincho mb-4">どなたでも参加できます。(令和４年２月２２日現在)</p></div>
            </div>
        )
    }

}

export default NormalClassHeader;