import React from "react";

class TryFree extends React.Component {

    render () {
        return(
            <div id="try-free" className='mt-6'>
                <div className="text-center lg:text-left mb-5">
                <div id="announce-head" className="text-center lg:text-left mb-3"><h1 className="inline-block w-56 lg:w-auto border-b lg:border-none border-solid border-black text-lg lg:text-2xl font-yu-mincho font-black">無料体験会について</h1></div>
                </div>    
                <div className='flex justify-center lg:block'>
                    <p className="font-yu-mincho text-sm">
                    通常教室の無料体験会を実施中です。お気軽にご連絡ください。<br />
                    メモ、筆記用具をお持ち下さい。<br />
                    内履きは教室によっては必要になりますが、見学時はなくても大丈夫です。<br />
                    運動しやすい服装でお越し下さい。
                    </p>
                </div>
            </div>
        )
    }

}

export default TryFree;