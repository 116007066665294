import React from "react";

class ToContact extends React.Component {


    render() {
        return(
            <div className={this.props.exClass + " text-center lg:text-right mt-6"}>
                <a href="/contact" className="bg-yuyu-btn inline-block text-center text-sm font-black font-yu-mincho px-8 py-1 lg:text-xl lg:w-[16rem]">お問合せはこちら</a>
            </div>    
        )
    }

}

export default ToContact;